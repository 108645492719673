<template>
  <div class="binary-tree">
    <ul>
      <li>
        <binary-node
          v-bind:exp="true"
          :side="TOP"
          :user.sync="tree"
          @clicked="onClick"
        />
        <ul>
          <li
            :class="
              getWeekerFiatNodeClass(
                tree.totals.activeFiatLeft - tree.totals.activeFiatRight >= 0
              )
            "
          >
            <binary-node
              v-bind:exp="
                tree.totals.activeFiatLeft - tree.totals.activeFiatRight >= 0
              "
              :side="LEFT"
              :user.sync="tree.binaryTree[0]"
              @clicked="onClick"
            />
            <ul>
              <li
                :class="
                  getWeekerFiatNodeClass(
                    tree.totals.activeFiatLeft - tree.totals.activeFiatRight >=
                      0
                  )
                "
              >
                <binary-node
                  v-bind:exp="
                    tree.totals.activeFiatLeft - tree.totals.activeFiatRight >=
                    0
                  "
                  :side="LEFT"
                  :user.sync="tree.binaryTree[2]"
                  @clicked="onClick"
                />
                <ul>
                  <li
                    :class="
                      getWeekerFiatNodeClass(
                        tree.totals.activeFiatLeft -
                          tree.totals.activeFiatRight >=
                          0
                      )
                    "
                  >
                    <binary-node
                      v-bind:exp="
                        tree.totals.activeFiatLeft -
                          tree.totals.activeFiatRight >=
                        0
                      "
                      :side="LEFT"
                      :user.sync="tree.binaryTree[6]"
                      @clicked="onClick"
                    />
                  </li>
                  <li
                    :class="
                      getWeekerFiatNodeClass(
                        tree.totals.activeFiatLeft -
                          tree.totals.activeFiatRight >=
                          0
                      )
                    "
                  >
                    <binary-node
                      v-bind:exp="
                        tree.totals.activeFiatLeft -
                          tree.totals.activeFiatRight >=
                        0
                      "
                      :side="LEFT"
                      :user.sync="tree.binaryTree[7]"
                      @clicked="onClick"
                    />
                  </li>
                </ul>
              </li>
              <li
                :class="
                  getWeekerFiatNodeClass(
                    tree.totals.activeFiatLeft - tree.totals.activeFiatRight >=
                      0
                  )
                "
              >
                <binary-node
                  v-bind:exp="
                    tree.totals.activeFiatLeft - tree.totals.activeFiatRight >=
                    0
                  "
                  :side="LEFT"
                  :user.sync="tree.binaryTree[3]"
                  @clicked="onClick"
                />
                <ul>
                  <li
                    :class="
                      getWeekerFiatNodeClass(
                        tree.totals.activeFiatLeft -
                          tree.totals.activeFiatRight >=
                          0
                      )
                    "
                  >
                    <binary-node
                      v-bind:exp="
                        tree.totals.activeFiatLeft -
                          tree.totals.activeFiatRight >=
                        0
                      "
                      :side="LEFT"
                      :user.sync="tree.binaryTree[8]"
                      @clicked="onClick"
                    />
                  </li>
                  <li
                    :class="
                      getWeekerFiatNodeClass(
                        tree.totals.activeFiatLeft -
                          tree.totals.activeFiatRight >=
                          0
                      )
                    "
                  >
                    <binary-node
                      v-bind:exp="
                        tree.totals.activeFiatLeft -
                          tree.totals.activeFiatRight >=
                        0
                      "
                      :side="LEFT"
                      :user.sync="tree.binaryTree[9]"
                      @clicked="onClick"
                    />
                  </li>
                </ul>
              </li>
            </ul>
          </li>
          <li
            :class="
              getWeekerFiatNodeClass(
                tree.totals.activeFiatLeft - tree.totals.activeFiatRight <= 0
              )
            "
          >
            <binary-node
              v-bind:exp="
                tree.totals.activeFiatLeft - tree.totals.activeFiatRight <= 0
              "
              :side="RIGHT"
              :user.sync="tree.binaryTree[1]"
              @clicked="onClick"
            />
            <ul>
              <li
                :class="
                  getWeekerFiatNodeClass(
                    tree.totals.activeFiatLeft - tree.totals.activeFiatRight <=
                      0
                  )
                "
              >
                <binary-node
                  v-bind:exp="
                    tree.totals.activeFiatLeft - tree.totals.activeFiatRight <=
                    0
                  "
                  :side="RIGHT"
                  :user.sync="tree.binaryTree[4]"
                  @clicked="onClick"
                />
                <ul>
                  <li
                    :class="
                      getWeekerFiatNodeClass(
                        tree.totals.activeFiatLeft -
                          tree.totals.activeFiatRight <=
                          0
                      )
                    "
                  >
                    <binary-node
                      v-bind:exp="
                        tree.totals.activeFiatLeft -
                          tree.totals.activeFiatRight <=
                        0
                      "
                      :side="RIGHT"
                      :user.sync="tree.binaryTree[10]"
                      @clicked="onClick"
                    />
                  </li>
                  <li
                    :class="
                      getWeekerFiatNodeClass(
                        tree.totals.activeFiatLeft -
                          tree.totals.activeFiatRight <=
                          0
                      )
                    "
                  >
                    <binary-node
                      v-bind:exp="
                        tree.totals.activeFiatLeft -
                          tree.totals.activeFiatRight <=
                        0
                      "
                      :side="RIGHT"
                      :user.sync="tree.binaryTree[11]"
                      @clicked="onClick"
                    />
                  </li>
                </ul>
              </li>
              <li
                :class="
                  getWeekerFiatNodeClass(
                    tree.totals.activeFiatLeft - tree.totals.activeFiatRight <=
                      0
                  )
                "
              >
                <binary-node
                  v-bind:exp="
                    tree.totals.activeFiatLeft - tree.totals.activeFiatRight <=
                    0
                  "
                  :side="RIGHT"
                  :user.sync="tree.binaryTree[5]"
                  @clicked="onClick"
                />
                <ul>
                  <li
                    :class="
                      getWeekerFiatNodeClass(
                        tree.totals.activeFiatLeft -
                          tree.totals.activeFiatRight <=
                          0
                      )
                    "
                  >
                    <binary-node
                      v-bind:exp="
                        tree.totals.activeFiatLeft -
                          tree.totals.activeFiatRight <=
                        0
                      "
                      :side="RIGHT"
                      :user.sync="tree.binaryTree[12]"
                      @clicked="onClick"
                    />
                  </li>
                  <li
                    :class="
                      getWeekerFiatNodeClass(
                        tree.totals.activeFiatLeft -
                          tree.totals.activeFiatRight <=
                          0
                      )
                    "
                  >
                    <binary-node
                      v-bind:exp="
                        tree.totals.activeFiatLeft -
                          tree.totals.activeFiatRight <=
                        0
                      "
                      :side="RIGHT"
                      :user.sync="tree.binaryTree[13]"
                      @clicked="onClick"
                    />
                  </li>
                </ul>
              </li>
            </ul>
          </li>
        </ul>
      </li>
    </ul>
  </div>
</template>

<script>
import BinaryNode from "./BinaryNode";
export default {
  name: "BinaryTree",
  props: ["tree"],
  components: {
    BinaryNode,
  },
  data() {
    return {
      user: {},
    };
  },
  methods: {
    onClick(value) {
      this.$emit("clicked", value);
    },
    getWeekerFiatNodeClass(exp) {
      if (exp === true) {
        return "strongerNode";
      } else {
        return "weakerNode";
      }
    },
  },
};
</script>

<style>
.binary-tree {
  overflow: auto;
}

.binary-tree ul {
  display: flex;
  position: relative;
  margin: 0;
  padding: 0px 0 0;
  transition: all 0.5s;
  flex-direction: row;
}

.binary-tree li {
  position: relative;
  padding: 20px 20px 0 20px;
  text-align: center;
  flex: none;
  list-style-type: none;
  transition: all 0.5s;
}

.binary-tree li::after,
.binary-tree li::before {
  content: "";
  position: absolute;
  top: 0;
  right: 50%;
  width: 50%;
  height: 25px;
}

.binary-tree li.strongerNode::after,
.binary-tree li.strongerNode::before {
  border-top: 1px solid #ccc;
}
.binary-tree li.weakerNode::after,
.binary-tree li.weakerNode::before {
  border-top: 2px solid #ffb09c;
}

.binary-tree li::after {
  left: 50%;
  right: auto;
}

.binary-tree li.strongerNode::after {
  border-left: 1px solid #ccc;
}
.binary-tree li.weakerNode::after {
  border-left: 2px solid #ffb09c;
}

.binary-tree li:only-child::after,
.binary-tree li:only-child::before {
  display: none;
}

.binary-tree li:only-child {
  padding-top: 0;
}

.binary-tree li:first-child {
  margin-left: auto;
  padding-left: 10px;
}

.binary-tree li:last-child {
  margin-right: auto;
  padding-right: 10px;
}

.binary-tree li:first-child::before,
.binary-tree li:last-child::after {
  border: 0 none;
}

.binary-tree li:last-child::before {
  border-radius: 0 5px 0 0;
}

.binary-tree li.strongerNode:last-child::before {
  border-right: 1px solid #ccc;
}
.binary-tree li.weakerNode:last-child::before {
  border-right: 2px solid #ffb09c;
}

.binary-tree li:first-child::after {
  border-radius: 5px 0 0 0;
}

.binary-tree li a {
  display: inline-block;
  padding: 5px 8px;
  min-width: 64px;
  height: 50px;
  line-height: 22px;
  color: #666;
  background-color: #fff;
  border: 1px solid #ccc;
  font-size: 16px;
  text-decoration: none;
  border-radius: 5px;
  transition: all 0.5s;
  vertical-align: top;
}

.binary-tree li a[href]:hover {
  color: #000;
  background-color: #eee;
  border-color: #aaa;
}

.binary-tree li a.active {
  color: #000;
  background-color: #c8e4f8;
  border-color: #94a0b4;
}
</style>