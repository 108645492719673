<template>
  <div>
    <v-btn
      v-if="user"
      elevation="12"
      small
      :loading="loading"
      :disabled="loading"
      :class="
        getRankClass(user.totals.rank) +
        ' px-2 icon-button ' +
        getHigherFiatNodeClass(exp)
      "
      @click="onClick(user.id)"
    >
      <font-awesome-icon
        class="text-wrapper fsize-2"
        :icon="rankIcon(user.totals.rank)"
      />
      <div class="text-wrapper fsize-2">{{ user.username }}</div>
    </v-btn>
    <v-btn
      v-else
      elevation="12"
      small
      :loading="loading"
      :disabled="true"
      color="grey"
      class="icon-button"
    ></v-btn>
  </div>
</template>

<script>
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faMale,
  faMedal,
  faTrophy,
  faGem,
  faCrown,
} from "@fortawesome/free-solid-svg-icons";
import { faGem as faGemS } from "@fortawesome/free-regular-svg-icons";

library.add(faMale, faMedal, faTrophy, faGem, faGemS, faCrown);
export default {
  name: "BinaryNode",
  props: ["user", "side", "exp"],
  components: {
    "font-awesome-icon": FontAwesomeIcon,
  },
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    getRankClass: function (rank) {
      const rankColors = [
        "prospect",
        "member",
        "bronze",
        "silver",
        "gold",
        "platinum",
        "ruby",
        "sapphire",
        "emerald",
        "diamond",
        "blue-diamond",
        "black-diamond",
        "royal-diamond",
        "crown",
        "founder",
      ];
      return rankColors[rank];
    },
    rankIcon: function (rank) {
      const rankIcons = [
        [""],
        ["fas", "male"],
        [""],
        [""],
        [""],
        ["fas", "trophy"],
        ["fas", "trophy"],
        ["fas", "gem"],
        ["fas", "gem"],
        ["far", "gem"],
        ["far", "gem"],
        ["far", "gem"],
        ["far", "gem"],
        ["fas", "crown"],
        ["fas", "crown"],
      ];
      return rankIcons[rank];
    },
    onClick(value) {
      this.$emit("clicked", value);
    },
    getHigherFiatNodeClass(exp) {
      if (exp === true) {
        return "";
      } else {
        return "weakerNode";
      }
    },
  },
};
</script>

<style scoped>
.text-bold {
  font-weight: 1000;
}
.icon-button {
  padding: 0.89rem !important;
  text-transform: unset !important;
  min-width: 30px;
}

.icon-wrapper {
  margin: 4px;
}

.text-wrapper {
  margin: 4px;
}

.bronze {
  background-color: #cd7f32 !important;
  color: white;
}

.silver {
  background-color: #c0c0c0 !important;
}

.gold {
  background-color: #ffd700 !important;
}

.platinum {
  background-color: #e5e4e2 !important;
}

.sapphire {
  background-color: #000a66 !important;
  color: white !important;
}

.emerald {
  background-color: #027218 !important;
  color: white;
}

.diamond {
  background-color: #bfefff !important;
}

.royal-diamond {
  background-color: #c0c0c0 !important;
}

.crown {
  background-color: #ffd700 !important;
}
.weakerNode {
  border: 2px solid #ffb09c;
}
</style>