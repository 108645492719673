<template>
    <div class="">
        <v-menu offset-y>
            <template v-slot:activator="{ on }">
                <v-btn color="primary"
                       class="text-unset binary-change p-1"
                       v-on="on">{{current}}</v-btn>
            </template>
            <v-list>
                <v-list-tile v-for="(item, index) in items" :key="index">
                    <v-list-tile-title @click="changeDirection(index)">{{ item.title }}</v-list-tile-title>
                </v-list-tile>
            </v-list>
        </v-menu>
    </div>
</template>

<script>
    import gql from "graphql-tag";

    export default {
        props: [
            "leg"
        ],
        data: () => ({
            items: [
                { title: "Automatic"},
                { title: "Left"},
                { title: "Right"},
            ],
            selected: null
        }),
        computed: {
            current() {
                const directions = [
                    this.$i18n.t('leg-auto'),
                    this.$i18n.t('leg-left'),
                    this.$i18n.t('leg-right'),
                ];
                return directions[this.leg];
            }
        },
        methods: {
            async changeDirection(value) {
                await this.$apollo.mutate({
                    mutation: gql`mutation($leg:Int!){updateInvite(leg:$leg)}`,
                    variables: {
                        leg: value,
                    },
                }).then(data => {
                    if (data.data.updateInvite) {
                        this.leg = value
                    }
                })
            }
        }
    };
</script>

<style scoped>
.binary-change {
    padding: 2px 1px;
    height: 16px;
    min-width: 45px !important;
}
</style>