<template>
    <div v-if="page">
        <div class="row">
            <binary-breadcrumbs class="col-md-12 breadcrumb-btns" :upline="root.binaryUpline" @clicked="onClick" />
        </div>
        <div class="row">
            <div class="col-md-12">
                <div class="card mb-3 nav-justified pb-3">
                    <binary-tree class="card-body py-0 mt-3 mb-0" :tree.sync="root" @clicked="onClick" />
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-6 col-xl-3 order-1 center-elem mb-3">
                <v-btn
                    color="primary"
                    class="text-normal"
                    @click="downLeft"
                    small
                    >
                    {{$t('down-left')}}
                </v-btn>
            </div>
            <div class="col-6 col-xl-3 order-2 order-xl-3 center-elem justify-content-end mb-3">

                <v-btn
                    color="primary"
                    class="text-normal"
                    @click="downRight"
                    small
                >
                    {{$t('down-right')}}
                </v-btn>
            </div>
            <div class="col-12 col-xl-6 order-3 order-xl-2 mb-3 justify-content-center">
                <affiliate-binary class="" :invite-id="page.viewer.inviteId" :leg="page.viewer.inviteLeg" />
            </div>
        </div>
        <div class="row">
            <div class="col-12 col-lg-12 col-xl-6 mb-3" v-if="root">
                <profile-box-tokens class="" :user.sync="root" />
            </div>
            <div class="col-12 col-lg-12 col-xl-6 mb-3" v-if="root">
                <volume-stats class=" " :user.sync="root.userBinaryTotals" :totals.sync="root.totals" :userId.sync="root.id" :title="statsTitle" />
            </div>
        </div>
    </div>


</template>

<script>
    import BinaryTree from "../components/Tree/BinaryTree";
    import ProfileBoxTokens from "../components/Tree/ProfileBoxTokens";
    import VolumeStats from "../components/Tree/VolumeStats";
    import BinaryBreadcrumbs from "../components/Tree/BinaryBreadcrumbs";
    import gql from "graphql-tag";
    import AffiliateBinary from "@/components/Tree/AffiliateBinary";
    export default {
        name: "BinaryPage",
        components: {
            AffiliateBinary,
            BinaryTree,
            ProfileBoxTokens,
            VolumeStats,
            BinaryBreadcrumbs,
        },
        data() {
            return {
                heading: this.$i18n.t("Binary"),
                page: null,
                root: null,
                statsTitle: this.$t('My Binary Data'),
                viewer: null
            }
        },
        methods: {
            onClick(value) {
                if (value === this.root.id) return;
                this.$apollo.query(
                    {
                        query: gql`query($id:ID!){user(id:$id){id,createdAt,username,firstName,lastName,email,activePackage,poolStatus,canViewDownline,totals{rank,tokens,shares,sharesA,held,activeVolumeLeft,activeVolumeRight,activeFiatLeft,activeFiatRight,inactiveVolumeLeft,inactiveVolumeRight,unpaidVolumeLeft,unpaidVolumeRight},userBinaryTotals{countLeft,countRight,countNewLeft,countNewRight,downLeft,downRight},binaryUpline(max:3){id,more,username},binaryTree(depth:3){id,username,totals{rank}}}}`,
                        variables: {
                            id: value
                        },
                    }
                ).then(data => {
                    this.root = data.data.user
                    this.root.binaryUpline.push(this.root)
                    this.$forceUpdate()
                })
            },
            downLeft() {
                if (!this.root.userBinaryTotals.downLeft) return;
                this.$apollo.query(
                    {
                        query: gql`query($id:ID!){user(id:$id){id,createdAt,username,firstName,lastName,email,activePackage,poolStatus,canViewDownline,totals{rank,tokens,shares,sharesA,held,activeVolumeLeft,activeVolumeRight,activeFiatLeft,activeFiatRight,inactiveVolumeLeft,inactiveVolumeRight,unpaidVolumeLeft,unpaidVolumeRight},userBinaryTotals{countLeft,countRight,countNewLeft,countNewRight,downLeft,downRight},binaryUpline(max:3){id,more,username},binaryTree(depth:3){id,username,totals{rank}}}}`,
                        variables: {
                            id: this.root.userBinaryTotals.downLeft
                        },
                    }
                ).then(data => {
                    this.root = data.data.user
                    this.root.binaryUpline.push(this.root)
                    this.$forceUpdate()
                })
            },
            downRight() {
                if (!this.root.userBinaryTotals.downRight) return;
                this.$apollo.query(
                    {
                        query: gql`query($id:ID!){user(id:$id){id,createdAt,username,firstName,lastName,email,activePackage,poolStatus,canViewDownline,totals{rank,tokens,shares,sharesA,held,activeVolumeLeft,activeVolumeRight,activeFiatLeft,activeFiatRight,inactiveVolumeLeft,inactiveVolumeRight,unpaidVolumeLeft,unpaidVolumeRight},userBinaryTotals{countLeft,countRight,countNewLeft,countNewRight,downLeft,downRight},binaryUpline(max:3){id,more,username},binaryTree(depth:3){id,username,totals{rank}}}}`,
                        variables: {
                            id: this.root.userBinaryTotals.downRight
                        },
                    }
                ).then(data => {
                    this.root = data.data.user
                    this.root.binaryUpline.push(this.root)
                    this.$forceUpdate()
                })
            },
        },
        computed: {

        },
        mounted() {
          if (this.$route.params.userId) {
              this.$apollo.query(
                  {
                      query: gql`query($id:ID!){user(id:$id){id,createdAt,username,firstName,lastName,email,activePackage,poolStatus,canViewDownline,totals{rank,tokens,shares,sharesA,held,activeVolumeLeft,activeVolumeRight,activeFiatLeft,activeFiatRight,inactiveVolumeLeft,inactiveVolumeRight,unpaidVolumeLeft,unpaidVolumeRight},userBinaryTotals{countLeft,countRight,countNewLeft,countNewRight,downLeft,downRight},binaryUpline(max:3){id,more,username},binaryTree(depth:3){id,username,totals{rank}}},viewer{id,inviteId,inviteLeg}}`,
                      variables: {
                          id: this.$route.params.userId
                      },
                  }
              ).then(data => {
                  this.root = data.data.user
                  this.root.binaryUpline.push(this.root)
              })
          }
        },
        apollo: {
            page: {
                query: gql`query{
                    viewer{
                        id,createdAt,username,firstName,lastName,email,activePackage,poolStatus,canViewDownline,inviteId,inviteLeg,
                        totals{rank,tokens,shares,sharesA,held,activeVolumeLeft,activeVolumeRight,activeFiatLeft,activeFiatRight,inactiveVolumeLeft,inactiveVolumeRight,unpaidVolumeLeft,unpaidVolumeRight},
                        userBinaryTotals{countLeft,countRight,countNewLeft,countNewRight,downLeft,downRight},
                        binaryUpline(max:3){id,more,username},
                        binaryTree(depth:3){id,username,totals{rank}}
                    },viewerIsAdmin}`,
                update: data => data,
                result({data}) {
                    if (data) {
                        this.$store.commit('user/updateAvailable', {
                            tokens: data.viewer.totals.tokens
                        })
                        if (data.viewerIsAdmin) {
                            this.$store.commit('user/setAdmin')
                        }
                        if (!this.root) {
                            this.root = data.viewer
                        }
                    }
                }
            }
        }
    }
</script>

<style scoped>

.breadcrumb-btns {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-bottom: 1rem;
    list-style: none;
    border-radius: 0.25rem;
}
</style>