<template>
    <div class="card">
        <div class="card-header text-unset text-left">
            {{$t('referral-header')}}
            <v-spacer />
            <div class="d-flex center-elem">
                {{$t('current-direction')}}
                <select-direction-menu :leg="leg" />
            </div>
        </div>
        <div class="d-flex card-body align-center">
            <div class="fsize-1 binary-link mr-3">
                {{generateLink(inviteId)}}
            </div>


            <v-btn v-if="inviteId"
                   color="primary"

                   v-clipboard:copy="generateLink(inviteId)"
                   class="mx-2 my-0 copy-button"
                   @click="snackbar = true"
            >
                <font-awesome-icon :icon="['far', 'clone']" small />
            </v-btn>

            <v-snackbar
                v-model="snackbar"
                :multi-line="multiLine"
            >
                {{ text }}

                <template v-slot:action="{ attrs }">
                    <v-btn
                        color="red"
                        text
                        v-bind="attrs"
                        @click="snackbar = false"
                    >
                        {{$t('close')}}
                    </v-btn>
                </template>
            </v-snackbar>

        </div>
    </div>
</template>

<script>
    import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

    import { library } from '@fortawesome/fontawesome-svg-core'
    import { faClone } from '@fortawesome/free-regular-svg-icons'

    library.add(faClone);

    import SelectDirectionMenu from "../Tree/SelectDirectionMenuBinary";
    export default {
        name: "AffiliateBinary",
        components: {
            SelectDirectionMenu,
            "font-awesome-icon": FontAwesomeIcon,
        },
        props: [
            "inviteId",
            "leg"
        ],
        data() {
            return {
                multiLine: true,
                snackbar: false,
                text: this.$i18n.t('referral-copy')
            }
        },
        methods: {
            directionName: function (direction) {
                const directions = [
                    "Automatic",
                    "Left",
                    "Right"
                ];
                return directions[direction];
            },
          generateLink: function(inviteId) {
              return this.baseURL+"/#/sign-up/" + inviteId;
          }
        }
    }
</script>

<style scoped>

.copy-button {
    max-width: 32px !important;
    min-width: 30px !important;
}

.referal-binary {
    max-width: 500px;
    left: 50%;
    right: 50%;
}

.binary-link {
    max-width: max-content;
}

</style>